.typography-basic {
  font-size: 14px;
  line-height: 20px;
}

.typography-card-title {
  font-size: 18px;
  line-height: 26px;
}

.typography-title {
  font-size: 16px;
  line-height: 22px;
}

.typography-card-label {
  font-size: 12px;
  line-height: 16px;
}

.typography-tab-title {
  font-size: 14px;
  line-height: 20px;
}

.typography-empty-title {
  font-size: 18px;
  line-height: 26px;
}

@media only screen and (max-width: 768px) {
  .typography-basic {
    font-size: 12px;
    line-height: 20px;
  }

  .typography-card-title {
    font-size: 16px;
    line-height: 22px;
  }

  .typography-tab-title {
    font-size: 16px;
    line-height: 22px;
  }

  .typography-empty-title {
    font-size: 24px;
    line-height: 28px;
  }
}
