@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Railroad Gothic';
  src: url(./assets/fonts/RailroadGothicCC.ttf);
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url(./assets/fonts/NeueHaasDisplayMediu.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url(./assets/fonts/NeueHaasDisplayRoman.ttf);
  font-weight: 500;
}

* {
  font-family: 'Railroad Gothic', sans-serif;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: #000;
  visibility: none;
}

@-moz-document url-prefix() {
  html,
  body {
    overflow-y: auto;
    scrollbar-color: transparent transparent;
  }
  #marketplace {
    overflow-y: auto;
    scrollbar-color: #2e2e2e #525252;
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar {
  width: 0px;
  width: 3px;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  width: 3px;
  margin-top: 1px;
  background: #525252;
}

.react-select__menu-portal {
  ::-webkit-scrollbar-track {
    background: #00000000;
  }
}

#marketplace-description {
  &::-webkit-scrollbar-track {
    background: #00000000 !important;
  }
}

::-webkit-scrollbar-track:hover {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  margin-bottom: 14px;
  border-radius: 2px;
  background: #676767;
}

::-webkit-scrollbar-thumb:hover {
  width: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c8ff88;
  width: 6px;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}
